var 
app = {
  init: function(){
    
    //cache
     
    app.$body = jQuery('body');
    app.isMobile = !!Modernizr.mq('only all and (max-width: 480px)');
    
    
    // IE 10
    if(document.body.style.msTouchAction != 'undefined' 
    || navigator.appVersion.indexOf("MSIE 10") !== -1){ 
        jQuery('body').addClass('ie10'); }
    
    
    // mobile swipe carousels  
    jQuery('.carousel.h-100')
      //.height(innerHeight*.66)
      //.height(innerHeight*.46)
      .bcSwipe({ threshold: 50 });
    
    
    jQuery('#featured-header')
      //.height(innerHeight*.66)
      //.height(innerHeight*.45)
      .bcSwipe({ threshold: 50 })
      .find('.slider-bg-img').hide();
    
    
    // Window scrolling
    var $body = jQuery('body');
    
    window.addEventListener('scroll', function(){
      if( window.scrollY > 100 ) $body.addClass('scroll');
      else $body.removeClass('scroll');
    });
    
    
    // HOME PAGE CLEANUP
    if( $body.hasClass('home') && !app.isMobile){

      jQuery('main > header').height( (innerHeight-73) - jQuery('footer').height());
      
    }
    
    // Search bar
    
    // show/hide
    jQuery('#menu-main-menu li.search a').text('').addClass('icon-search');
    
    jQuery('#menu-main-menu li.search a').on('click', function(e){
      e.preventDefault();
      e.stopPropagation();
      jQuery('#search').toggleClass('active');
      jQuery('#input-search').eq(0).focus();
    });
    
    jQuery('#icon-close').on('click', function(e){
      e.preventDefault();
      e.stopPropagation();
      jQuery('#search').toggleClass('active');
      jQuery('#input-search').eq(0).blur();
    });
    
    // placeholder copy
    jQuery('.search-field').focus(function(){
      jQuery(this)
        .data('placeholder', jQuery(this).attr('placeholder'))
        .attr('placeholder','');
    }).blur(function(){
      jQuery(this).attr('placeholder', jQuery(this).data('placeholder'));
    });
    
    // cache
    var 
    $res = jQuery('#ajax-results'),
    $stitle = jQuery('#results-title');
    
    // AJAX search
/*
    jQuery('#input-search').on('keyup', function(e){
      var q = jQuery(this).val();
      if(q.length < 2) return false;
      var 
      search = jQuery.ajax({
        url: '/?s='+q+'&ajax',
        dataType: 'json',
        method: 'GET',
        error: function(e,a){ console.log('error');console.log(arguments); },
        success: function(d){
          $res.empty();
          $stitle.empty();
          
          if(d.length > 1) $stitle.html('Results for "'+q+'" <br/>');
          else $stitle.html('No results <br/>'); 
            
          for(var i=0;i<d.length;i++){
            $res.append('<li><a href="/allied/?p='+d[i].ID+'">'
            + '<small>'+d[i].post_type+':</small>' 
            + d[i].post_title + '</a></li>'); 
          }
        }  
      });
       
    });
*/
    
  }
};


/*! Bootstrap Carousel Swipe   */
    !function(t){t.fn.bcSwipe=function(e){var n={threshold:50};return e&&t.extend(n,e),this.each(function(){function e(t){1==t.touches.length&&(u=t.touches[0].pageX,c=!0,this.addEventListener("touchmove",o,!1))}function o(e){if(c){var o=e.touches[0].pageX,i=u-o;Math.abs(i)>=n.threshold&&(h(),t(this).carousel(i>0?"next":"prev"))}}function h(){this.removeEventListener("touchmove",o),u=null,c=!1}var u,c=!1;"ontouchstart"in document.documentElement&&this.addEventListener("touchstart",e,!1)}),this}}(jQuery);
    

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');
      app.init();

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


